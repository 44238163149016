import React from 'react';
//images
import Image from '../assets/profile.png'
//icons
import { FaGithub, FaYoutube, FaDribbble } from 'react-icons/fa';
// type-animation
import {TypeAnimation } from 'react-type-animation'
//motion
import {motion} from 'framer-motion'
//variants
import { fadeIn } from '../variants'





const Banner = () => {
  return (
  <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
        {/*text*/}
          <div className='flex-1 text-center font-primary lg:text-left'>
              <motion.h1 
              variants={fadeIn('up',0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{once: false, amount:0.7}}
              className='text-[45px]  leading-[0.8] lg:text-[80px] '>
                HELLENBARTH <span>RÓBERT</span>
              </motion.h1>
         <motion.div 
         variants={fadeIn('up',0.4)}
         initial='hidden'
         whileInView={'show'}
         viewport={{once: false, amount:0.7}}
         className='mb-6 text-[30px] lg:text-[60px] font-primary leading-[1]'>
           <span className='text-white mr-4'></span>
           <TypeAnimation sequence={[
            'Developer',
            2000,
            'Devops',
            2000,
            'Docsis Expert',
            2000,
           ]}
           speed={50}
           className='text-accent'
           wrapper='span'remote
           repeat={Infinity} />
         </motion.div>
         <motion.p 
         variants={fadeIn('up',0.5)}
         initial='hidden'
         whileInView={'show'}
         viewport={{once: false, amount:0.7}}
         className='mb-8 max-w-lg mx-auto lg:mx-0'>
        Üdvözöllek!<br/> 
        Azért hoztam létre az oldalt , hogy tudjak belőle tanulni, fejlesszem magam. 
         </motion.p>
            <motion.div 
            variants={fadeIn('up',0.6)}
            initial='hidden'
            whileInView={'show'}
            viewport={{once: false, amount:0.7}}
            className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              {/* <button className='btn btn-lg'>Kapcsolat</button> */}
              
              {/* <a href='#' className='text-gradient btn-link'>My Portfolio</a> */}
            </motion.div>
            
            {/*Socilas*/}
            {/* <motion.div 
            variants={fadeIn('up',0.7)}
            initial='hidden'
            whileInView={'show'}
            viewport={{once: false, amount:0.7}}
            className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
              <a href='#'>
              <FaGithub />  
              </a>
              <a href='#'>
              <FaYoutube />  
              </a>
              <a href='#'>
              <FaDribbble />  
              </a>
            </motion.div> */}
            </div>
           {/* <motion.div 
           variants={fadeIn('left',0.5)}
           initial='hidden'
           whileInView={'show'}
           viewport={{once: false, amount:0.7}}
           className='hidden lg:flex flex-1 max-w-[280px] lg:max-w-[382px] mix-blend-lighten'>
            <img src={Image} alt=''/>
            
           </motion.div> */}
        </div>
      </div>
  </section>
  );
};

export default Banner;
