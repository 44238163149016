import React from 'react';
import CountUp from 'react-countup';
import { motion} from 'framer-motion';
import { fadeIn } from '../variants';
import { useInView } from 'react-intersection-observer';


const About = () => {
  const {ref, inView} = useInView({
    threshold: 0.5,
  });
  console.log(inView);
  return (
    <section className='section' id='about' ref={ref}>
      <div className="container mx-auto" >
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div
          variants={fadeIn('right',0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3}}
          className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'>
          </motion.div>
          <motion.div 
          variants={fadeIn('left',0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.}}
          className='flex-1'>
          <h2 className='h2 text-accent'>Rólam</h2>
          <h3 className='h3 mb-4'>
            Devops Engineerként és OSS fejlesztőként dolgozok egy paicvezető telekommunikációs cégnél. 
          </h3>
             <p className='font-secondary text-ld mb-6'>
             Imádom az informatikát, itt munka közben szórakozok , tanulok, hobbyzok. 
             Amikor felállok az asztaltól pedig a családommal lehetek.
             </p>
             <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
               <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {inView && 
                <CountUp start={100} end={3} duration={3} />}            
                </div>
                  <div className='font-primary text-sm tracking-[2px]'>
                  Év
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {inView && 
                <CountUp start={0} end={13} duration={3} />}    
                %        
                </div>
                  <div className='font-primary text-sm tracking-[2px]'>
                  React
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {inView && 
                <CountUp start={0} end={60} duration={3} />}      
                %      
                </div>
                  <div className='font-primary text-sm tracking-[2px]'>
                  Docker <br /> 
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                {inView && 
                <CountUp start={0} end={30} duration={3} />}      
                %      
                </div>
                  <div className='font-primary text-sm tracking-[2px]'>
                  K8S <br /> 
                </div>
              </div>
            </div>
            <div className='flex gap-x-8 items-center'>
                  {/* <button className='btn btn-lg'>Kapcsolat</button> */}
                  
                  <button className='btn btn-lg'>
                  <a href='#' className='text-activ btn-link'>
                    Kezdőlap                
                  </a>
                  </button>
                </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;