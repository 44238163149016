import React, { useEffect } from 'react';
// components
import messaging from 'firebase/app';

import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';

const App = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/worker.js', {scope: "/"}).then((registration) => {
      console.log('Service worker registered');
    }).catch(err => {
      console.warn('Service worker registration failed:',err);
    });
  }
  // useEffect(() => {
  //   messaging.requestPermission()
  //     .then(() => messaging.getToken())
  //     .then(token => console.log('Token : ', token))
  //     .catch(error => console.log('Error:', error));
  // }, []);

  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Header />
      <Banner />
      <Nav />
      <About />
      {/* <Services />
      <Work /> */}
      {/* <Contact /> */}
      <div className='h-[4000px]'></div>
    </div>
  );
};

export default App;
